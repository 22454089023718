import React, { useEffect, useState } from 'react'
import { pure } from 'recompose'
import { dataStore, pageStore, userStore } from "state/store-zustand";

import { fetchPlacesDetails } from "components/utils/testHelpers.js"

import useContextTheme from 'components/utils/useContextTheme'
import usePageLoading from 'components/utils/usePageLoading'

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import PlaceForm from 'components/forms/addPlaceForm'

import AuthDialog from "components/account/authDialog"
import Branding from "components/account/branding"


import '../styles/search.scss'
import 'components/forms/form.scss'

const EditBusiness = ({ data, location, params,...props }) => {
  // Page context and options
  const pageLoader = usePageLoading()
  const themeContext = useContextTheme()
  const { theme, themeClass } = themeContext

  const isClient = pageStore((state) => state.isClient)
  const embedded = pageStore((state) => state.embedded)
  const placeID = dataStore((state) => state.placeID)
  const placeCurrent = dataStore((state) => state.placeCurrents)
  const setPlaceCurrent = dataStore((state) => state.setPlaceCurrent)

  const isLoggedIn = userStore((state) => state.isLoggedIn)

  // Form content and state
  const alreadyLoaded = placeCurrent != null
  const [isLoading, setIsLoading] = useState(!alreadyLoaded)
  const [hasError, setHasError] = useState(false)

  const [openDialog, setOpenDialog] = useState(false)


  const handleSubmit = async (event, data) => {
    console.log('TODO: handleSubmit ', event, data);
    debugger
    //setClearForm(true)
    //setTitle('Thanks for helping us!')
    //setMessage(<p>Vibemap will include this business in our city pages, vibe guides, and other content.</p>)
  }

  if (!isClient) {
    return null
  }

  useEffect(() => {
    const fetchData = async (id) => {
      setIsLoading(true)
      const details = await fetchPlacesDetails(id, undefined, true)
        .catch(e => {
          console.log(`Error with place `, id, e)
          setHasError(true)
        })

      if (details) {
        setPlaceCurrent(details.data)
        setIsLoading(false)
        const place = details.data?.properties?.hotspots_place
      } else {
        setHasError(true)
        setIsLoading(false)
      }
    }

    if (placeCurrent == null) {
      fetchData(placeID)
    }

  }, [placeID])


  const PlaceFormMemo = React.memo(PlaceForm);

  return (
    <>
      <main className={`page add-event ${pageLoader} ${embedded ? 'embedded' : ''} ${theme ? theme : ''}`}>

        <Branding />

        <AuthDialog openDialog={openDialog} />

        <div className='container'>
          {isLoggedIn ?
            <div>
              <Alert severity="info">
                Edit the business below. Your edits will be reviewed by our team within 48 hours. Send any questions to <a href>businesses@vibemap.com</a>
              </Alert>

            </div>
            : <Alert severity="info">
              You are not logged in. You can suggest edits or login to make changes.
              <Button variant="outlined" onClick={() => setOpenDialog(true)}>Login</Button>
            </Alert>
          }

          <PlaceFormMemo
            onSubmitCallback={handleSubmit}
            doSearch={false}
            isLoggedIn={isLoggedIn}
            lookupEvent={false}
            updateOnly={true} />
        </div>
      </main>
    </>
  )
}

export default pure(EditBusiness);